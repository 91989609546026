<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="jumbotron" :style="{ 'margin-right': sidebarWidth }">

    <video id="myVideo" autoplay loop muted playsinline poster="@/assets/api3.jpg">
      <source src="@/assets/1716553782.mp4" type="video/mp4" />
    </video>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="slideshow-container">
          <div style="text-align: center">
            <!-- </div>class="mySlides fade"> -->
            <h1 class="header-txt">Welcome To Arrab-AI</h1>
            <p class="description-text">
              Welcome to ARRAB-AI, your intelligent assistant available directly through WhatsApp! Explore the amazing
              features we offer to make your life easier and more connected.
            </p>
              <p class="description-text-Ar">
              العرّاب: تطبيق مبتكر يجلب لك قوة الذكاء الاصطناعي عبر WhatsApp. تواصل مع ChatGPT بسهولة ويسر، واحصل على إجابات فورية واستشارات ذكية في أي وقت ومن أي مكان. العّراب هو شريكك الرقمي الذي يجعل الحياة أكثر بساطة وإنتاجية.
            </p>
            <a href="https://wa.me/447888860551" target="_blank" ><h2 class="header-txt-WhatsApp">Try Me</h2>
              <img src="@/assets/logo.png" alt="" class="img-size-small" />
              <img src="@/assets/WhatsAppLogo.png" alt="" class="img-size-small" />

          </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="slideshow-container">
          <div>
            <!-- </div>class="mySlides fade"> -->
            <h1 class="header-txt">Multilingual Messaging</h1>
            <p class="description-text">
              Send direct messages in any language you prefer through the WhatsApp application. ARRAB-AI will provide
              accurate and relevant answers to your queries, no matter the language.
            </p>
            <transition>
              <img src="@/assets/HomePage_SendMessageLogo.jpg" alt="" class="img-size" />
            </transition>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="jumbotron">
          <h1 class="header-txt" style="text-shadow: 1px 1px 2px #808080, 2px 2px 4px #bebebe">
            Image Reading
          </h1>

          <p class="description-text fade-in-text">
            ARRAB-AI can analyze and interpret the content of your images. Simply send an image through WhatsApp, and
            receive a detailed response based on the image content.
          </p>
          <img src="@/assets/HomePage_ReadFromImage.jpg" class="img-size" />
        </div>
      </div>
      <div class="row col-sm-12">
        <div class="breake-line"></div>
      </div>
      <div class="row section4">
        <div class="col-sm-4 sec4-Position">
          <h3 class="header-txt">Text to Speech</h3>
          <p class="description-text">
            Convert any text to speech effortlessly. Just send a command to ARRAB-AI, and listen to the text being read
            out loud.
          </p>
          <img src="@/assets/HomePage_TextToSpeech.jpg" alt="..." class="img-size-round" />
        </div>
        <div class="col-sm-4 sec4-Position">
          <h3 class="header-txt">Imagination Image Creation</h3>
          <p class="description-text">
            Unleash your creativity with ARRAB-AI! Use the DALL-E3 engine to create imaginative and unique images
            directly through WhatsApp.
          </p>
          <img src="@/assets/HomePage_CreateImage.jpg" alt="..." class="img-size-round" style="margin-top: 10px" />
          
        </div>
        <div class="col-sm-4 sec4-Position">
          <h3 class="header-txt">Voice Commands</h3>
          <p class="description-text">
            Give your commands or requests by sending your voice message to ARRAB-AI. Receive precise and proper answers
            in response.
          </p>
          <img src="@/assets/HomePage_VoiceCommand.jpg" alt="..." class="img-size-round" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>





</script>




<style scoped>
.main {
  background-image: url("@/assets/WhatsAppBackground2.png");
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  opacity: 0.6;
}

.outer {
  position: absolute;
  z-index: 10;
  width: 80% - var(sidebarWidth);
  height: 100%;
  margin-left: 25px;
  top: 0;
  bottom: 0;

}

.bottomStyle {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  max-width: 100%;
  min-height: var(--height-pane-footer);
  padding-top: var(--compose-box-top-bottom-padding);
  padding-bottom: var(--compose-box-top-bottom-padding);
  padding-left: var(--compose-box-left-right-padding);
  padding-right: var(--compose-box-left-right-padding);
  background-color: var(--rich-text-panel-background);
  border-left: 1px solid var(--border-stronger);
}




form {
  height: 100%;
}

.topInput {
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
}


.buttomInput {
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
}



button {
  width: 100%;
}

.header-txt {
  --text-opacity: 1;
  color: rgba(99, 179, 237, var(--text-opacity));
  text-shadow: 1px 1px 2px #808080, 2px 2px 4px #bebebe;
  align-content: center;
  text-align: center;
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, sans-serif;
}
.header-txt-WhatsApp {
  --text-opacity: 1;
  font-size: 4em;
  color: #4ed37f;
  text-shadow: 1px 1px 2px #808080, 2px 2px 4px #bebebe;
  align-content: center;
  text-align: center;
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, sans-serif;
}
.description-text-Ar {
  direction: rtl;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.932);
  display: flex;
  justify-content: flex-start;
  text-rendering: optimizeLegibility;
  padding-left: 20px;
  -webkit-font-smoothing: antialiased;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.description-text {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.932);
  display: flex;
  justify-content: flex-start;
  text-rendering: optimizeLegibility;
  padding-left: 20px;
  -webkit-font-smoothing: antialiased;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.img-size {
  width: 80%;
  height: 70%;

  border-radius: 10px;
  margin-bottom: 15px;
}

.img-size-round {
  width: 80%;
  height: 80%;

  border-radius: 25%;
  margin-bottom: 15px;
}


.img-size-small {
  width: 10%;
  border-radius: 50%;
  margin-bottom: 15px;

}

#myVideo {
  /* position: fixed; */
  /* right: 0;
  top: 0; */
  margin-top: 20px;
  width: 100%;
  height: 10em;
  max-height: 10em;
  /* max-width: 100%;
  min-height: 10%;
  max-height: 40%; */
  object-fit: cover;
  z-index: -100;
  transform: translate(0%, -19%);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}

.boxshadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  /* flex:1 1 0; */
}

.imgleft {
  margin-left: 0px;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.imagleft {
  margin-left: 0px;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  width: 100%;
}

.imgright {
  margin-right: 0px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.imgtop {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.section4 {
  margin: 20px 0px 15px 0px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.sec4-Position{
  position: relative;
  margin: auto;
}
.nowrap {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.breake-line {
  width: 100%;
  height: 2px;
  background-image: linear-gradient(to right,
      #3f79f7 0%,
      #dcf083 51%,
      #3f79f7 100%);
}
a {
  text-decoration: none;
}
a label:hover {
  cursor: pointer;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>