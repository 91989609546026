import { createStore } from 'vuex'

export default createStore({
  state: {
    GetUsersPlans_URL : 'https://iportal.arrab-ai.com/Portal/GetUserPlan',
    Obj_GetUsersPlans: {},
    VerifyUser_URL : 'https://iportal.arrab-ai.com/Portal/VerifyUser',
    Obj_VerifyUser: {},
    GetUserSubscriberInfo_URL : 'https://iportal.arrab-ai.com/Portal/GetUserSubscriberInfo',
    Obj_GetUserSubscriberInfo: {},

    Payment_Step:{
      PlanView : true,
      VerifyView:false,
      ManageView:false,
      UpdateView:false

    },
    progressVisible:false,
    strUserName:"Arrab-AI.com"
    

  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
