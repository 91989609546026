import {ref,computed} from 'vue';

export const collapsed = ref(false)
export const toggleSidebar = () =>(collapsed.value = !collapsed.value)

export const SIDEBAR_WIDTH = 120;
export const SIDEBAR_WIDTH_COLLAPSED = 35;
export const SIDEBAR_CONTACT_WIDTH = 220;
export const sidebarWidth = computed(
    () => `${collapsed.value ? SIDEBAR_WIDTH_COLLAPSED :SIDEBAR_WIDTH}px`
);

export const sidebarWidthnumber = computed(
    () => collapsed.value ? SIDEBAR_WIDTH_COLLAPSED :SIDEBAR_WIDTH
);


export const WINDOW_WIDTH = window.innerWidth;
export const CONTENT_WINDOW_WIDTH_Collapsed = (window.innerWidth - SIDEBAR_CONTACT_WIDTH - SIDEBAR_WIDTH_COLLAPSED -1) ;
export const CONTENT_WINDOW_WIDTH_Open = (window.innerWidth - SIDEBAR_CONTACT_WIDTH - SIDEBAR_WIDTH -1) ;
export const contentwidth = computed(
    () => `${collapsed.value ? CONTENT_WINDOW_WIDTH_Collapsed :CONTENT_WINDOW_WIDTH_Open}px`
);