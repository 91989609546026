
<template lang="">
    <router-link :to="to" class="link" :class="{active : isActive}">
       
        <i :class="icon" class="icon"></i>
        <transition name="fade">
            <span v-if="!collapsed">
                <slot />
            </span>
        </transition>
    </router-link>
    </template>
    <script>
    
    import { useRoute } from 'vue-router';
    import { collapsed } from './state';
    import { computed } from 'vue';
    
    export default {
        name: "SidebarLink",
        props: {
            to: { type: String, required: true },
            icon: { type: String, required: true }
        },
        setup(props) {
            const route = useRoute();
            const isActive = computed(() => route.path === props.to)
    
            return { isActive, collapsed }
    
        }
    }
    </script>
    <style scoped>
    .fade-enter-active,
    .fade-leave-active{
        transition: opacity 0.1s;
    
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
    .link {
        display: flex;
        flex-direction: row;
        align-items: center;
    
        cursor: pointer;
        position: relative;
        font-weight: 400;
        user-select: none;
    
        margin: 0.1em 0;
        padding: 0;
        border-radius: 0.25em;
        height: 1.5em;
    
        color: white;
        text-decoration: none;
    
    }
    
    .link:hover {
        background-color: var(--sidebar-item-hover);
    }
    
    .link.active {
        background-color: var(--sidebar-item-active);
    }
    
    .link .icon {
        flex-shrink: 0;
        width: 25px;
        margin-left: 5px;
    
    }
    </style>